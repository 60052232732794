import { rem } from '@mantine/core';

interface IconProps extends React.ComponentPropsWithoutRef<'svg'> {
  size?: number | string;
}

export default function BrokenLinkIcon({ size, style, ...others }: IconProps) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      stroke='currentColor'
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeWidth='1.5'
      viewBox='0 0 96 96'
      fill='currentColor'
      style={{ width: rem(size), height: rem(size), ...style }}
      {...others}
    >
      <path d='M34.8436 12.2447C34.5341 12.3854 34.098 12.6808 33.8729 12.9058C33.0287 13.7498 33.0147 13.8624 32.9725 19.0531C32.9162 24.7783 32.9584 25.0878 33.9151 26.0443C34.4356 26.5648 34.7452 26.7477 35.2798 26.8602C36.7711 27.1838 38.0795 26.5226 38.7267 25.13C38.9659 24.6095 38.994 24.1453 38.994 19.5032C38.994 14.4813 38.994 14.4391 38.6564 13.7358C38.2765 12.9058 38.0092 12.6526 37.165 12.2728C36.3772 11.9211 35.5752 11.9071 34.8436 12.2447Z' />
      <path d='M66.5976 12.1884C64.1637 12.6244 61.9126 13.581 60.1118 14.9455C59.5209 15.3815 56.4679 18.3356 53.3305 21.4866C47.8295 27.0149 47.6044 27.2681 47.4496 27.9433C47.126 29.35 47.731 30.6723 48.9972 31.3053C49.7991 31.6992 50.4182 31.7554 51.3045 31.5163C51.7829 31.3897 52.7959 30.4331 57.7763 25.4816C63.5587 19.7423 63.7135 19.6016 64.8812 19.0249C66.6117 18.1949 67.5965 17.9839 69.3833 18.0683C70.326 18.1105 71.1982 18.2371 71.7751 18.4341C74.4482 19.2922 76.7274 21.5851 77.5856 24.2859C77.8389 25.0877 77.8951 25.5941 77.8951 27.029C77.8951 28.9843 77.7544 29.5891 76.8681 31.3194C76.4179 32.2196 75.5456 33.134 66.0208 42.6714L55.666 53.0246L54.3294 53.6576C52.7537 54.4172 51.9376 54.6001 50.2775 54.6001C48.139 54.6001 46.2115 53.8827 44.467 52.4479C43.3836 51.5476 43.046 51.3507 42.3285 51.21C41.0763 50.9849 39.796 51.6461 39.2051 52.8136C38.8252 53.5873 38.769 54.2062 39.0081 55.0925C39.3036 56.2037 42.0049 58.37 44.3263 59.3829C49.2082 61.507 55.0188 60.8458 59.2395 57.6948C60.6323 56.668 80.7512 36.4257 81.4265 35.3988C85.2251 29.5188 84.789 22.4431 80.315 17.154C78.7111 15.2831 75.8692 13.3981 73.5478 12.6948C71.3108 12.0055 68.6517 11.8226 66.5976 12.1884Z' />
      <path d='M19.7475 18.2513C18.5657 18.8561 17.9748 19.883 18.0592 21.1772C18.1014 21.8524 18.1999 22.1619 18.5516 22.6683C19.2691 23.6952 28.1608 32.4307 28.7799 32.7121C29.5818 33.0919 30.9184 32.9793 31.6218 32.4729C32.48 31.8821 32.8599 31.221 32.9162 30.1941C32.9584 29.5611 32.9021 29.1672 32.7192 28.7733C32.4378 28.1544 23.715 19.2641 22.6739 18.5467C22.1533 18.1809 21.886 18.0965 21.1263 18.0543C20.4791 18.0262 20.0711 18.0825 19.7475 18.2513Z' />
      <path d='M14.1903 33.1331C13.2617 33.3863 12.7271 33.8647 12.2628 34.8355C11.9111 35.567 11.9111 36.369 12.2769 37.1569C12.6567 38.001 12.91 38.2683 13.7401 38.6482C14.4435 38.9858 14.4857 38.9858 19.5084 38.9858C24.1512 38.9858 24.6155 38.9577 25.136 38.7185C26.5289 38.0713 27.1901 36.7629 26.8665 35.2716C26.6555 34.2727 25.6847 33.316 24.6577 33.0909C23.5884 32.8658 15.0766 32.8939 14.1903 33.1331Z' />
      <path d='M41.5545 37.2134C39.0643 37.6917 36.8695 38.6342 35.0687 39.9846C33.5633 41.11 15.2875 59.4814 14.57 60.5786C12.4175 63.9124 11.5874 67.598 12.1923 71.2835C12.4737 73.07 12.8817 74.2938 13.6977 75.8693C16.3005 80.8631 21.4498 84 27.0493 84C30.032 84 33.4789 82.8465 35.8847 81.0459C36.3912 80.6661 39.0362 78.12 41.7515 75.391C46.6476 70.4817 46.7038 70.4114 46.8586 69.6658C47.0415 68.7796 46.8867 68.02 46.3521 67.2322C45.916 66.5852 44.7904 65.9943 44.0026 65.9943C42.8067 66.0084 42.4128 66.3179 37.2353 71.4664C32.4659 76.221 32.2689 76.3757 31.1153 76.9525C29.3848 77.7824 28.3999 77.9934 26.6132 77.909C24.1511 77.7965 22.3362 77.0087 20.6479 75.3348C18.9736 73.6467 18.1858 71.8321 18.0732 69.3704C17.9888 67.5839 18.1858 66.6133 19.0299 64.869L19.6068 63.6733L29.1315 54.1641L38.6422 44.6408L39.8381 44.064C41.3857 43.3185 42.4268 43.0653 43.9322 43.0653C46.1692 43.0653 48.0685 43.7546 49.8412 45.2316C51.0371 46.2303 51.5717 46.4835 52.43 46.4835C53.6821 46.4835 54.6669 45.8365 55.1875 44.683C55.497 44.0359 55.5252 43.3888 55.286 42.6292C54.7795 40.913 50.9668 38.3529 47.6465 37.4948C45.9019 37.0446 43.1303 36.918 41.5545 37.2134Z' />
      <path d='M70.6917 57.3423C69.4536 57.9614 68.8205 59.2979 69.13 60.7048C69.2426 61.2395 69.4255 61.549 69.946 62.0695C70.9027 63.0262 71.2123 63.0684 76.9384 63.0122C82.1299 62.97 82.2424 62.9559 83.0866 62.1117C84.0011 61.1973 84.2402 59.9592 83.7197 58.8196C83.3398 57.9754 83.0866 57.7081 82.2565 57.3282C81.553 56.9906 81.5108 56.9906 76.4741 56.9906H71.4092L70.6917 57.3423Z' />
      <path d='M64.7686 63.2794C63.6008 63.8561 62.9959 64.8971 63.0803 66.1913C63.1225 66.8665 63.221 67.1759 63.5727 67.6823C64.2621 68.667 73.1819 77.4448 73.7728 77.7121C75.1375 78.3451 76.868 77.7543 77.5996 76.4038C77.9373 75.7708 77.9935 75.5598 77.9373 74.8143C77.8951 74.125 77.7966 73.8155 77.4448 73.3091C76.7555 72.3244 67.8356 63.5467 67.2447 63.2794C66.5976 62.9699 65.3736 62.984 64.7686 63.2794Z' />
      <path d='M59.3238 69.1031C58.4656 69.3141 57.7622 69.8768 57.3542 70.6786L57.0024 71.396V76.4601C57.0024 81.2569 57.0306 81.5664 57.2979 82.1713C57.6778 83.0153 57.931 83.2826 58.7611 83.6624C59.957 84.2251 61.181 84 62.1236 83.0716C62.9677 82.2276 62.9818 82.115 63.024 76.9243C63.0522 73.8296 63.0099 71.9024 62.9115 71.4242C62.5738 69.7783 60.9559 68.7233 59.3238 69.1031Z' />
    </svg>
  );
}
