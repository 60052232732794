import { rem } from '@mantine/core';

interface IconProps extends React.ComponentPropsWithoutRef<'svg'> {
  size?: number | string;
}

export default function BoulderingIconOutlined({
  size,
  style,
  ...others
}: IconProps) {
  return (
    <svg
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      stroke='currentColor'
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeWidth='1.5'
      style={{ width: rem(size), height: rem(size), ...style }}
      {...others}
    >
      <g clip-path='url(#clip0_6406_1221)'>
        <path d='M8 8C8 9.06087 8.42143 10.0783 9.17157 10.8284C9.92172 11.5786 10.9391 12 12 12C13.0609 12 14.0783 11.5786 14.8284 10.8284C15.5786 10.0783 16 9.06087 16 8C16 6.93913 15.5786 5.92172 14.8284 5.17157C14.0783 4.42143 13.0609 4 12 4C10.9391 4 9.92172 4.42143 9.17157 5.17157C8.42143 5.92172 8 6.93913 8 8Z' />
        <path d='M6 22V20C6 18.9391 6.42143 17.9217 7.17157 17.1716C7.92172 16.4214 8.93913 16 10 16H14C15.0609 16 16.0783 16.4214 16.8284 17.1716C17.5786 17.9217 18 18.9391 18 20V22' />
        <path d='M1 23V5C1 3.93913 1.77262 2.92172 3.14788 2.17157C4.52315 1.42143 6.38841 1 8.33333 1H15.6667C17.6116 1 19.4768 1.42143 20.8521 2.17157C22.2274 2.92172 23 3.93913 23 5V23' />
      </g>
    </svg>
  );
}
