import { rem } from '@mantine/core';

interface IconProps extends React.ComponentPropsWithoutRef<'svg'> {
  size?: number | string;
}

export default function TradIconOutlined({
  size = 16,
  style,
  ...others
}: IconProps) {
  return (
    <svg
      viewBox='0 0 96 96'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      style={{ width: rem(size), height: rem(size), ...style }}
      {...others}
    >
      <path
        d='M32.6954 14.7364C19.4555 18.5096 10.8927 32.1509 12.116 47.171C12.5477 51.96 14.8503 60.8123 17.6566 68.2861C20.6068 76.3403 25.7156 82 29.961 82C37.5884 82 44.4962 74.8891 46.0792 65.4562C46.367 63.7873 46.9427 62.4087 47.4464 62.4087C49.3172 62.4087 66.2988 57.1843 71.4077 55.0075C75.0775 53.4837 78.0996 51.5972 80.4742 49.2752L84 45.7923L83.4963 41.5838C81.9133 28.4504 73.5664 19.7431 58.8874 15.8974C51.6918 14.0108 37.3725 13.3578 32.6954 14.7364ZM57.808 18.5096C71.2638 21.7748 78.8192 29.3936 80.6181 41.4387C81.1218 44.7039 81.0498 44.7765 77.7398 47.7514C73.8542 51.1618 67.4501 53.8465 54.1383 57.6922L45.1438 60.2319L41.2581 58.2727C35.7895 55.4429 29.0976 55.4429 25.4278 58.2002C23.9887 59.3611 22.1898 61.5379 21.5422 63.1343C20.247 66.0367 20.247 66.0367 19.3835 64.4404C15.354 56.6038 13.9149 40.8582 16.5053 33.6747C19.3116 25.9107 24.4924 20.8315 32.2636 18.0016C35.4297 16.8407 37.3725 16.6956 44.6401 16.9858C49.3892 17.2035 55.2896 17.8565 57.808 18.5096ZM36.581 59.5788C39.1714 60.3044 39.3153 60.4495 38.8836 62.6263C37.7323 68.1409 33.7747 74.744 31.688 74.744C30.3928 74.744 28.0902 71.8415 25.9315 67.5605L24.0607 63.7148L25.7156 61.9007C28.5939 58.7806 31.5441 58.1276 36.581 59.5788ZM43.201 65.166C41.474 74.5263 33.343 81.4195 27.8024 78.2994C24.9961 76.7031 21.7581 71.6238 22.3337 69.7373C22.7654 68.2861 23.0533 68.5037 25.068 71.9867C28.0902 76.9208 30.2489 78.2269 33.4869 76.9208C36.2932 75.7598 39.6032 70.8982 41.1142 65.4562C42.3375 61.2477 43.9925 60.9575 43.201 65.166Z'
        fill='currentColor'
        stroke='currentColor'
      />
      <path
        d='M39.7473 26.709C38.6679 27.87 37.8044 28.9584 37.8044 29.176C37.8044 29.3937 38.8118 29.9742 40.0351 30.4821C42.1218 31.3529 42.3377 31.2803 44.4964 29.176C46.8709 26.709 46.727 25.9108 43.489 25.1852C42.0498 24.8224 41.1864 25.1852 39.7473 26.709Z'
        fill='currentColor'
        stroke='currentColor'
      />
      <path
        d='M30.177 26.7814C28.8818 27.5796 27.6585 28.5955 27.3707 29.1034C26.2914 30.6997 31.5441 29.1034 33.487 27.2168C35.1419 25.5479 35.1419 25.4028 33.8467 25.4028C33.0552 25.4028 31.4002 26.0558 30.177 26.7814Z'
        fill='currentColor'
        stroke='currentColor'
      />
      <path
        d='M49.6772 31.9332L46.9429 34.763L48.2381 37.738C48.9576 39.3344 49.6772 40.6404 49.8931 40.6404C50.7565 40.6404 55.1459 36.7947 55.8654 35.4161C56.4411 34.3277 56.4411 33.5295 55.7215 32.1509C53.9946 28.8856 52.6994 28.8131 49.6772 31.9332Z'
        fill='currentColor'
        stroke='currentColor'
      />
    </svg>
  );
}
