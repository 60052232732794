import { rem } from '@mantine/core';

interface IconProps extends React.ComponentPropsWithoutRef<'svg'> {
  size?: number | string;
}

export default function SportIcon({ size, style, ...others }: IconProps) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 96 96'
      fill='currentColor'
      style={{ width: rem(size), height: rem(size), ...style }}
      {...others}
    >
      <path d='M28.5188 13.224C18.1648 18.48 15.7242 31.8 20.0137 59.16C21.7887 70.248 23.2678 75.576 25.4126 78.816C27.2615 81.552 31.551 84 34.5093 84H36.4322L34.1395 81.48C30.9594 77.88 29.776 74.856 27.7792 64.92C25.1167 51.888 24.0074 38.424 25.0428 31.368C26.1521 24.096 28.9625 17.76 32.6604 14.52L35.5447 12H33.1781C31.8468 12.072 29.776 12.576 28.5188 13.224Z' />
      <path d='M42.4972 12.6479C35.7671 14.2319 30.664 19.4159 28.7412 26.6159C25.9308 37.3439 29.7766 70.0319 34.8796 77.9519C36.4327 80.4719 39.6128 82.5599 41.9055 82.5599C44.8638 82.5599 49.5971 77.9519 61.726 63.1919C77.257 44.3999 78.0705 43.0319 77.9966 35.7599C77.9226 25.9679 73.0415 20.3519 60.8385 15.8159C56.9188 14.4479 45.5294 11.7119 44.9378 12.0719C44.8638 12.1439 43.7544 12.3599 42.4972 12.6479ZM41.018 35.6159C42.867 41.7359 44.6419 53.3999 44.1242 56.0639C43.5326 59.1599 39.6128 65.2799 38.2077 65.2799C36.8764 65.2799 35.989 62.6159 34.3619 54.1199C32.8828 46.1999 33.2525 35.3999 35.1015 31.0799L36.3587 28.1999L38.1337 30.2159C39.1691 31.2959 40.4264 33.7439 41.018 35.6159ZM65.276 31.3679C67.4207 33.2399 67.7165 33.8879 67.7165 37.1999C67.7165 40.5119 67.4207 41.1599 65.276 43.0319C63.7968 44.3279 62.0958 45.1199 60.6906 45.1199C59.2854 45.1199 57.5844 44.3279 56.1053 43.0319C53.9605 41.1599 53.6647 40.5119 53.6647 37.1999C53.6647 33.8879 53.9605 33.2399 56.1053 31.3679C57.5844 30.0719 59.2854 29.2799 60.6906 29.2799C62.0958 29.2799 63.7968 30.0719 65.276 31.3679Z' />
    </svg>
  );
}
