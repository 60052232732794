import { rem } from '@mantine/core';

interface IconProps extends React.ComponentPropsWithoutRef<'svg'> {
  size?: number | string;
}

export default function RopeIcon({ size, style, ...others }: IconProps) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 36 36'
      fill='currentColor'
      style={{ width: rem(size), height: rem(size), ...style }}
      {...others}
    >
      <path d='M24.57,18.83a.75.75,0,0,1-.7-.48.76.76,0,0,1,.44-1c.06,0,6.32-2.45,7.11-6.18.32-1.5-.33-3.07-1.94-4.68S26.3,4.26,24.8,4.58c-3.73.8-6.16,7.05-6.18,7.11a.76.76,0,0,1-1,.44.75.75,0,0,1-.43-1c.11-.29,2.74-7.08,7.26-8,2-.44,4.07.35,6.06,2.34s2.78,4,2.35,6.06c-1,4.52-7.76,7.15-8,7.26A.65.65,0,0,1,24.57,18.83Z' />
      <path d='M23.29,17.55a.78.78,0,0,1-.68-.42.76.76,0,0,1,.34-1c1.49-.73,5.43-3.18,5.83-5.59a2.68,2.68,0,0,0-.93-2.37,2.65,2.65,0,0,0-2.38-.93c-2.42.39-4.86,4.33-5.59,5.82a.75.75,0,1,1-1.35-.67c.13-.24,3-6,6.7-6.63A4.09,4.09,0,0,1,28.91,7.1a4.09,4.09,0,0,1,1.34,3.67c-.6,3.67-6.39,6.58-6.63,6.7A.8.8,0,0,1,23.29,17.55Z' />
      <path d='M22,16.26a.77.77,0,0,1-.61-.31.75.75,0,0,1,.17-1c1.9-1.37,4.61-3.81,4.7-4.94,0-.11,0-.14-.05-.17s-.05-.06-.17,0c-1.13.09-3.57,2.8-4.94,4.7a.75.75,0,0,1-1.05.17.76.76,0,0,1-.17-1c.62-.85,3.82-5.14,6-5.31a1.67,1.67,0,0,1,1.83,1.83c-.17,2.22-4.46,5.42-5.31,6A.82.82,0,0,1,22,16.26Z' />
      <path d='M10.43,33a7.13,7.13,0,0,1-5-2.46c-2-2-2.78-4-2.35-6.06,1-4.51,7.76-7.15,8.05-7.26a.75.75,0,1,1,.53,1.4c-.06,0-6.32,2.46-7.11,6.18-.32,1.5.33,3.07,1.94,4.68s3.18,2.25,4.68,1.94c3.72-.79,6.16-7,6.18-7.11a.75.75,0,1,1,1.4.53c-.11.29-2.75,7.08-7.26,8.05A5.76,5.76,0,0,1,10.43,33Z' />
      <path d='M10.13,30.31a4.29,4.29,0,0,1-3-1.4,4.14,4.14,0,0,1-1.35-3.68c.61-3.67,6.39-6.57,6.64-6.7a.75.75,0,1,1,.67,1.35c-1.49.74-5.43,3.18-5.83,5.59a2.69,2.69,0,0,0,.93,2.38,2.67,2.67,0,0,0,2.38.93c2.42-.4,4.86-4.34,5.59-5.82a.75.75,0,0,1,1.35.66c-.13.25-3,6-6.7,6.64A4.6,4.6,0,0,1,10.13,30.31Z' />
      <path d='M9.92,27.76a1.61,1.61,0,0,1-1.19-.49,1.65,1.65,0,0,1-.49-1.35c.18-2.22,4.47-5.42,5.32-6a.75.75,0,1,1,.88,1.21c-1.9,1.37-4.61,3.81-4.7,4.94,0,.12,0,.15,0,.17s.05.06.17.05c1.13-.09,3.57-2.8,4.94-4.7a.75.75,0,1,1,1.22.88c-.62.86-3.82,5.14-6,5.32Z' />
      <path d='M23.71,21.14a.77.77,0,0,1-.53-.22l-8.1-8.1a.75.75,0,1,1,1.06-1.06l8.1,8.1a.75.75,0,0,1,0,1.06A.79.79,0,0,1,23.71,21.14Z' />
      <path d='M22.05,22.8a.79.79,0,0,1-.54-.22l-8.09-8.1a.75.75,0,1,1,1.06-1.06l8.1,8.1a.75.75,0,0,1,0,1.06A.79.79,0,0,1,22.05,22.8Z' />
      <path d='M20.38,24.46a.74.74,0,0,1-.53-.22l-8.09-8.1a.75.75,0,1,1,1.06-1.06l8.09,8.1a.74.74,0,0,1,0,1.06A.71.71,0,0,1,20.38,24.46Z' />
      <path d='M28.54,32.56a.75.75,0,0,1-.6-.3,7.53,7.53,0,0,1-1.29-5.6,8.79,8.79,0,0,0-1.58-6.37.75.75,0,0,1,1.23-.86,10.09,10.09,0,0,1,1.84,7.31c-.1,1.7-.17,3,1,4.62a.76.76,0,0,1-.16,1A.74.74,0,0,1,28.54,32.56Z' />
      <path d='M21.78,30.06a.76.76,0,0,1-.54-.23.75.75,0,0,1,0-1.06c3.4-3.25,2.75-6.39,2.72-6.53a.75.75,0,0,1,.57-.89.76.76,0,0,1,.9.56c0,.16.89,4.08-3.15,7.94A.75.75,0,0,1,21.78,30.06Z' />
    </svg>
  );
}
