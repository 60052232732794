import { rem } from '@mantine/core';

interface IconProps extends React.ComponentPropsWithoutRef<'svg'> {
  size?: number | string;
  color?: string;
}

export default function PointIcon({
  size = 16,
  style,
  color,
  ...others
}: IconProps) {
  return (
    <svg
      viewBox='0 0 36 36'
      xmlns='http://www.w3.org/2000/svg'
      style={{ width: rem(size), height: rem(size), ...style }}
      {...others}
    >
      <circle cx='18' cy='18' r='16' fill={color} strokeWidth='4' />
    </svg>
  );
}
