import { rem } from '@mantine/core';

interface IconProps extends React.ComponentPropsWithoutRef<'svg'> {
  size?: number | string;
}

export default function BoulderingIcon({ size, style, ...others }: IconProps) {
  return (
    <svg
      viewBox='0 0 15 15'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      stroke='currentColor'
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeWidth='1.5'
      style={{ width: rem(size), height: rem(size), ...style }}
      {...others}
    >
      <g clip-path='url(#clip0_6401_1221)'>
        <path d='M5 4.375C5 5.03804 5.26339 5.67393 5.73223 6.14277C6.20107 6.61161 6.83696 6.875 7.5 6.875C8.16304 6.875 8.79893 6.61161 9.26777 6.14277C9.73661 5.67393 10 5.03804 10 4.375C10 3.71196 9.73661 3.07607 9.26777 2.60723C8.79893 2.13839 8.16304 1.875 7.5 1.875C6.83696 1.875 6.20107 2.13839 5.73223 2.60723C5.26339 3.07607 5 3.71196 5 4.375Z' />
        <path d='M3.75 13.125V11.875C3.75 11.212 4.01339 10.5761 4.48223 10.1072C4.95107 9.63839 5.58696 9.375 6.25 9.375H8.75C9.41304 9.375 10.0489 9.63839 10.5178 10.1072C10.9866 10.5761 11.25 11.212 11.25 11.875V13.125' />
        <path d='M0 2C0 1.46957 0.526784 0.96086 1.46447 0.585787C2.40215 0.210714 3.67392 0 5 0H10C11.3261 0 12.5979 0.210714 13.5355 0.585787C14.4732 0.96086 15 1.46957 15 2V3V14C15 14.2761 14.7761 14.5 14.5 14.5C14.2239 14.5 14 14.2761 14 14V3V2.04016C13.9892 2.02365 13.9671 1.99488 13.9247 1.95397C13.7993 1.83283 13.5592 1.67227 13.1641 1.51426C12.3752 1.19869 11.2366 1 10 1H5C3.76336 1 2.62479 1.19869 1.83586 1.51426C1.44084 1.67227 1.20071 1.83283 1.07526 1.95397C1.0329 1.99488 1.01079 2.02365 1 2.04016V3V14C1 14.2761 0.776142 14.5 0.5 14.5C0.223858 14.5 0 14.2761 0 14V3V2Z' />
      </g>
      <defs>
        <clipPath id='clip0_6401_1221'>
          <rect width='15' height='15' fill='none' />
        </clipPath>
      </defs>
    </svg>
  );
}
