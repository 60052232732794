import { rem } from '@mantine/core';

interface IconProps extends React.ComponentPropsWithoutRef<'svg'> {
  size?: number | string;
}

export default function TradIcon({ size, style, ...others }: IconProps) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 96 96'
      fill='currentColor'
      style={{ width: rem(size), height: rem(size), ...style }}
      {...others}
    >
      <path d='M33.3844 14.4603C23.5765 17.0052 15.86 25.1487 13.0474 36.0551C11.0281 43.8351 11.8214 50.5971 16.3648 64.4847L18.6004 71.3194L20.2591 67.2476C21.1966 64.9936 22.3505 62.6669 22.8553 62.0125C26.0285 58.1589 32.7353 57.7954 40.3797 60.9946L45.5721 63.1759L54.731 60.6311C70.0919 56.4139 78.2411 52.633 82.1354 47.9068C84.371 45.2893 84.5153 43.3261 83.0008 37.5093C79.9719 25.6576 72.1112 18.7502 58.1205 15.551C51.7742 14.0968 37.2787 13.5151 33.3844 14.4603ZM46.2212 26.1666C46.6539 26.6029 46.0048 27.6935 44.6346 29.1477C42.4711 31.2563 42.2548 31.329 40.1634 30.4565C38.9374 29.9475 37.9277 29.3658 37.9277 29.1477C37.9277 28.9296 38.7931 27.8389 39.8749 26.6756C41.3172 25.1487 42.1826 24.7851 43.625 25.1487C44.6346 25.3668 45.7885 25.8758 46.2212 26.1666ZM33.6007 27.1846C31.6536 29.075 26.389 30.6746 27.4708 29.075C28.2641 27.6935 32.3747 25.3668 33.9613 25.3668C35.2594 25.3668 35.2594 25.5122 33.6007 27.1846ZM55.8849 32.1288C56.606 33.5103 56.606 34.3101 56.0291 35.4008C55.3079 36.7822 50.9088 40.6359 50.0434 40.6359C49.827 40.6359 49.1059 39.3271 48.3847 37.7275L47.0866 34.7464L49.827 31.9107C52.8559 28.7842 54.154 28.8569 55.8849 32.1288Z' />
      <path d='M37.5673 67.1751C36.1971 70.8833 33.2403 74.8096 31.8701 74.8096C30.4999 74.8096 28.1921 71.9739 26.1729 67.9022L24.3699 64.2667L23.5045 66.448C23.0718 67.6114 22.3507 70.0835 21.9901 71.9739C21.1247 76.0457 22.1343 78.227 26.0286 80.6264C31.9422 84.3346 40.3078 80.2629 44.2742 71.6831C45.1396 69.7927 45.8608 67.9022 45.8608 67.3932C45.8608 66.5934 40.7405 63.9032 39.2981 63.9032C39.0097 63.9032 38.2885 65.3574 37.5673 67.1751Z' />
    </svg>
  );
}
