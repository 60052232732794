import { rem } from '@mantine/core';

interface IconProps extends React.ComponentPropsWithoutRef<'svg'> {
  size?: number | string;
}

export default function LogoIcon({ size, style, ...others }: IconProps) {
  return (
    <svg
      viewBox='0 0 64 64'
      xmlns='http://www.w3.org/2000/svg'
      fill='currentColor'
      stroke='currentColor'
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeWidth='1.5'
      style={{ width: rem(size), height: rem(size), ...style }}
      {...others}
    >
      <path d='M10.0068 8.01458C9.94553 8.02749 9.73723 8.07911 9.55344 8.11783C9.05108 8.23399 8.38943 8.90511 8.16888 9.52461C7.99735 10.015 7.98509 11.8865 8.0096 28.3548L8.04636 46.6301L8.42619 47.1334C9.10009 48.011 9.39415 48.1143 11.4894 48.2175C13.6581 48.3337 14.2585 48.3982 16.2557 48.7338C21.6469 49.663 26.3151 51.9862 29.7091 55.4192L30.285 56V37.4279C30.285 27.2062 30.236 18.6235 30.1747 18.3525C29.8807 17.036 27.0013 13.6804 24.8938 12.1962C21.7694 10.015 17.775 8.59536 13.6213 8.20817C12.0407 8.0533 10.1906 7.96296 10.0068 8.01458Z' />
      <path d='M51.911 8.0663C46.7648 8.38895 42.6602 9.7183 39.1069 12.2092C36.9994 13.6676 34.12 17.0361 33.826 18.3526C33.7647 18.6236 33.7157 27.2063 33.7157 37.4151V55.9872L34.3651 55.3548C37.8571 51.8959 42.3661 49.6631 47.745 48.7339C49.7422 48.3983 50.3426 48.3338 52.5113 48.2176C54.6065 48.1144 54.9006 48.0111 55.5745 47.1335L55.9543 46.6302L55.9911 28.4194C56.0156 15.7068 55.9911 10.0539 55.8931 9.7183C55.746 9.15042 55.2559 8.51802 54.8026 8.2728C54.3125 8.00177 53.6508 7.96305 51.911 8.0663Z' />
    </svg>
  );
}
