import BoulderingIcon8a from './8a/BoulderingIcon8a';
import LogoIcon from './LogoIcon';
import FacebookIcon from './brands/Facebook';
import GoogleIcon from './brands/Google';
import AreaIcon from './custom/AreaIcon';
import BadAnchorIcon from './custom/BadAnchorIcon';
import BoulderingIcon from './custom/BoulderingIcon';
import BoulderingIconOutlined from './custom/BoulderingIconOutlined';
import BrokenLinkIcon from './custom/BrokenLink';
import ClimberIcon from './custom/ClimberIcon';
import CragIcon from './custom/CragIcon';
import PointIcon from './custom/PointIcon';
import RopeIcon from './custom/RopeIcon';
import SportIcon from './custom/SportIcon';
import SportIconOutlined from './custom/SportIconOutlined';
import TradIcon from './custom/TradIcon';
import TradIconOutlined from './custom/TradIconOutlined';
import * as Steepness from './custom/steepness';

export {
  AreaIcon,
  BadAnchorIcon,
  BoulderingIcon,
  BoulderingIcon8a,
  BoulderingIconOutlined,
  BrokenLinkIcon,
  ClimberIcon,
  CragIcon,
  FacebookIcon,
  GoogleIcon,
  LogoIcon,
  PointIcon,
  RopeIcon,
  SportIcon,
  SportIconOutlined,
  Steepness,
  TradIcon,
  TradIconOutlined,
};
