import { rem } from '@mantine/core';

interface IconProps extends React.ComponentPropsWithoutRef<'svg'> {
  size?: number | string;
}

export default function BadAnchorIcon({ size, style, ...others }: IconProps) {
  return (
    <svg
      viewBox='0 0 24 24'
      xmlns='http://www.w3.org/2000/svg'
      stroke='currentColor'
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeWidth='1.5'
      fill='currentColor'
      style={{ width: rem(size), height: rem(size), ...style }}
      {...others}
    >
      <path d='M4.85,14.34a2.37,2.37,0,0,0,.8-2.65,1.06,1.06,0,0,1,.08-.88A.48.48,0,0,1,6,10.58l.11,0-.1.05a.5.5,0,0,0-.36.61.49.49,0,0,0,.48.38l.12,0a1.59,1.59,0,0,0,.63-.33s0,0,0,0a1.44,1.44,0,0,0,.78,1.11,1.72,1.72,0,0,0,.77.16,3.61,3.61,0,0,0,1.47-.37.5.5,0,0,0,.23-.67.49.49,0,0,0-.67-.22,2,2,0,0,1-1.35.2c-.09,0-.2-.12-.25-.4a.5.5,0,0,0-.56-.4,2.19,2.19,0,0,0,.2-1.26A9.4,9.4,0,0,1,7.45,8,2.56,2.56,0,0,0,5.79,5.94a.5.5,0,0,0-.4.92c.52.23,1.05.7,1.06,1.16a9.75,9.75,0,0,0,.1,1.53,1.18,1.18,0,0,1-.15.76.55.55,0,0,0,0-.31.5.5,0,0,0-.58-.41,1.5,1.5,0,0,0-1,.69A2.06,2.06,0,0,0,4.69,12c.15.53.2.92-.55,1.65a.51.51,0,0,0,0,.71.51.51,0,0,0,.36.15A.54.54,0,0,0,4.85,14.34Zm1.41-3.87,0,0Z' />
      <path d='M13.63,20.34a11.69,11.69,0,0,1-1.18-.52,1.11,1.11,0,0,1-.59-.66.46.46,0,0,1,0-.39,1,1,0,0,0,.06-.16.85.85,0,0,1,0,.17.51.51,0,0,0,.2.68.5.5,0,0,0,.24.06.52.52,0,0,0,.44-.26,1.68,1.68,0,0,0,.18-.69l.06.05a9.3,9.3,0,0,0,1.42.6,1.05,1.05,0,0,1,.75.77.51.51,0,0,0,.5.45h0a.5.5,0,0,0,.45-.55,2,2,0,0,0-1.42-1.62,8.69,8.69,0,0,1-1.3-.54.5.5,0,0,0-.63.16,2.16,2.16,0,0,0-.78-1A8.68,8.68,0,0,1,11,16a.49.49,0,0,0-.69,0,4,4,0,0,0-.11-.75c-.14-.66-.2-1,.68-1.39a.5.5,0,1,0-.43-.9,1.91,1.91,0,0,0-1.23,2.5,3.2,3.2,0,0,1,.08.48s0-.06-.05-.09a.5.5,0,0,0-.7,0,1.35,1.35,0,0,0-.4.6.37.37,0,0,0-.05-.11,3.14,3.14,0,0,0-3.55-.89.49.49,0,0,0-.29.64.5.5,0,0,0,.64.3,2.17,2.17,0,0,1,2.39.54.51.51,0,0,0,.41.2A.52.52,0,0,0,8,17.05S8,17,8.07,17a2,2,0,0,0,.7,1.51,11.54,11.54,0,0,0,1.39,1.12.52.52,0,0,0,.29.09.51.51,0,0,0,.41-.21l0-.07h0A2.1,2.1,0,0,0,12,20.71a11,11,0,0,0,1.29.57.47.47,0,0,0,.17,0,.5.5,0,0,0,.17-1ZM12,18.56a.48.48,0,0,0,0-.12Zm-.91-.34a1.48,1.48,0,0,0-.22.69.58.58,0,0,0-.1-.12,9.88,9.88,0,0,1-1.3-1A1,1,0,0,1,9.07,17a.57.57,0,0,1,.08-.27.5.5,0,0,0,.15.67.48.48,0,0,0,.28.08.52.52,0,0,0,.42-.22,2,2,0,0,0,.26-.59.42.42,0,0,0,.08.12,10.27,10.27,0,0,0,1.16.93,1.06,1.06,0,0,1,.45.65.51.51,0,0,0-.2-.28A.5.5,0,0,0,11.06,18.22Z' />
      <path d='M17,14.1a1.29,1.29,0,0,0-.37,1.13,3.86,3.86,0,0,0,1.85,2.34.53.53,0,0,0,.24.06.5.5,0,0,0,.44-.25A.49.49,0,0,0,19,16.7a3,3,0,0,1-1.35-1.61.3.3,0,0,1,.08-.28c.45-.44,1.52.07,1.76.25a.5.5,0,0,0,.59-.81C19.66,14,18,13.1,17,14.1Z' />
      <circle cx='19.86' cy='13.23' r='0.5' />
      <path d='M21.76,12.36a2.2,2.2,0,0,0-1.3-1.07,2.17,2.17,0,0,0-2.19.55,2.67,2.67,0,0,1-.48.41,1.78,1.78,0,0,1-.47.12,3,3,0,0,0-1.23.44c-1.15.75-1,2.31-.57,3.18A14.7,14.7,0,0,0,17,18.28a3.71,3.71,0,0,0,1.33,1.08,1.59,1.59,0,0,0,.47.14h.08a.5.5,0,0,0,.5-.42.5.5,0,0,0-.42-.57,1.13,1.13,0,0,1-.24-.08,2.94,2.94,0,0,1-.92-.76,12.76,12.76,0,0,1-1.32-2.11c-.17-.35-.48-1.48.21-1.92a2,2,0,0,1,.85-.3,2.73,2.73,0,0,0,.74-.2,3.32,3.32,0,0,0,.75-.61,1.19,1.19,0,0,1,1.19-.3,1.21,1.21,0,0,1,.7.59,1,1,0,0,1,.08.79,11.14,11.14,0,0,0-.43,1.91l-.06.38a.51.51,0,0,0,.41.58.49.49,0,0,0,.57-.42l.07-.38a11.34,11.34,0,0,1,.39-1.77A2,2,0,0,0,21.76,12.36Z' />
      <path d='M6.25,5.86a.5.5,0,0,0,.7-.1.51.51,0,0,0-.11-.7c-.39-.29-2-1.15-3-.16A1.29,1.29,0,0,0,3.42,6,3.84,3.84,0,0,0,5.27,8.37a.45.45,0,0,0,.25.07A.49.49,0,0,0,6,8.18a.5.5,0,0,0-.19-.68A3,3,0,0,1,4.41,5.9a.3.3,0,0,1,.09-.29C4.94,5.18,6,5.69,6.25,5.86Z' />
      <circle cx='6.66' cy='4.04' r='0.5' />
      <path d='M4.62,9.78a.5.5,0,0,0,.32-.88,3,3,0,0,1-.4-.41A14.46,14.46,0,0,1,3.22,6.38C3.06,6,2.74,4.9,3.43,4.45a2.15,2.15,0,0,1,.85-.29A2.37,2.37,0,0,0,5,4a2.8,2.8,0,0,0,.75-.6A1.19,1.19,0,0,1,7,3.05a1.25,1.25,0,0,1,.71.58,1,1,0,0,1,.07.79,12.54,12.54,0,0,0-.43,1.92l-.06.38a.5.5,0,0,0,1,.16L8.3,6.5a11.09,11.09,0,0,1,.39-1.77,2,2,0,0,0-.14-1.56A2.15,2.15,0,0,0,7.26,2.1a2.18,2.18,0,0,0-2.2.55,2.67,2.67,0,0,1-.48.41,2.28,2.28,0,0,1-.47.12,2.92,2.92,0,0,0-1.23.44c-1.14.74-1,2.31-.57,3.18A14.7,14.7,0,0,0,3.74,9.09a4.13,4.13,0,0,0,.55.57A.5.5,0,0,0,4.62,9.78Z' />
      <path d='M19.15,15.23a4.62,4.62,0,0,0-.42-.25.5.5,0,0,0-.48.88c.17.09.32.19.32.19h0a2.74,2.74,0,1,1-4.07,3.41.5.5,0,1,0-.9.44A3.7,3.7,0,0,0,17,22a3.74,3.74,0,0,0,2.19-6.77Z' />
      <path d='M13.68,17.63a.46.46,0,0,0,.19,0,.51.51,0,0,0,.47-.31,3,3,0,0,1,.76-1.07.5.5,0,0,0,.09-.7.5.5,0,0,0-.7-.09A4.12,4.12,0,0,0,13.41,17,.5.5,0,0,0,13.68,17.63Z' />
    </svg>
  );
}
