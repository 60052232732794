import { rem } from '@mantine/core';

interface IconProps extends React.ComponentPropsWithoutRef<'svg'> {
  size?: number | string;
}

export default function AreaIcon({ size, style, ...others }: IconProps) {
  return (
    <svg
      viewBox='0 0 36 36'
      xmlns='http://www.w3.org/2000/svg'
      stroke='currentColor'
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeWidth='1.5'
      fill='none'
      style={{ width: rem(size), height: rem(size), ...style }}
      {...others}
    >
      <path d='M3.39,19.57a.77.77,0,0,0,.36.09.75.75,0,0,0,.66-.39,40.15,40.15,0,0,0,1.73-3.78c.11-.27.22-.63.35-1C7,12.76,8,9.93,9.72,9.93c1.23,0,2.18,2.6,2.81,4.33.09.23.17.45.25.67a7.54,7.54,0,0,0-3.94,1.94.75.75,0,0,0,0,1.06.77.77,0,0,0,1.07,0,6,6,0,0,1,4-1.61h0a4.32,4.32,0,0,1,2.53.88,10.58,10.58,0,0,0,2.88,1.31,8.56,8.56,0,0,0,2.2.37h.25a.74.74,0,0,0,.63-.82.77.77,0,0,0-.82-.67,6.53,6.53,0,0,1-1.87-.32,9.07,9.07,0,0,1-2.49-1.14,6,6,0,0,0-2.79-1c-.14-.34-.28-.72-.44-1.14-.23-.62-.47-1.29-.75-1.93.05-.07.11-.12.16-.2a21.58,21.58,0,0,0,1.27-2.43c1-2.08,2.24-4.68,3.66-4.68S20.82,6.7,21.46,8c.15.32.29.6.41.82A6.44,6.44,0,0,0,26,12.3a5.21,5.21,0,0,1,1.64.8,7.19,7.19,0,0,1,1.53,1.71,5.72,5.72,0,0,0,2.81,2.4.65.65,0,0,0,.3.06.76.76,0,0,0,.69-.45.75.75,0,0,0-.39-1A4.37,4.37,0,0,1,30.35,14a8.64,8.64,0,0,0-1.83-2,6.52,6.52,0,0,0-2.08-1,4.82,4.82,0,0,1-2.33-1.44c1.22-1.88,2-2.06,2.21-2.06.88,0,1.41.6,2.21,1.57.2.24.4.49.62.73a10.47,10.47,0,0,0,2.77,2.05.76.76,0,0,0,.33.08.75.75,0,0,0,.33-1.42,9.44,9.44,0,0,1-2.32-1.72c-.2-.22-.39-.44-.57-.67C28.88,7,28,5.88,26.32,5.88c-1,0-2,.73-3.12,2.25l0,0c-.11-.2-.23-.46-.38-.75C22,5.73,20.68,3,18.28,3s-3.78,2.94-5,5.54c-.3.62-.59,1.22-.87,1.73A3.25,3.25,0,0,0,9.72,8.43c-2.85,0-4,3.48-4.65,5.57-.12.37-.23.69-.33.94a34.85,34.85,0,0,1-1.65,3.61A.75.75,0,0,0,3.39,19.57Z' />
      <path d='M32.61,31.59l-.25-.18c-4.6-3.34-13.58-8.8-22.21-7.86a.74.74,0,0,0-.67.82.75.75,0,0,0,.83.67c8.11-.89,16.75,4.38,21.17,7.58.37.28.43.32.6.36l.17,0a.75.75,0,0,0,.36-1.41Z' />
      <path d='M7.6,20.76a.79.79,0,0,0-1.42,0L3,29.78a.76.76,0,0,0,.71,1H6.14v1.47a.75.75,0,0,0,1.5,0V30.78H10a.75.75,0,0,0,.62-.32.74.74,0,0,0,.09-.68ZM4.81,29.28l2.08-6,2.09,6Z' />
      <path d='M29.9,27.16a.75.75,0,0,0,.75-.75V25.5h1.6a.75.75,0,0,0,.71-1l-2.35-6.75a.79.79,0,0,0-1.42,0L26.84,24.5a.77.77,0,0,0,.1.69.75.75,0,0,0,.61.31h1.6v.91A.74.74,0,0,0,29.9,27.16Zm0-6.88L31.19,24H28.6Z' />
      <path d='M24.52,24.19a.76.76,0,0,0,.75-.75v-.78h1.4a.73.73,0,0,0,.61-.31.75.75,0,0,0,.1-.68l-2.16-6.2a.75.75,0,0,0-1.41,0l-2.16,6.2a.75.75,0,0,0,.1.68.73.73,0,0,0,.61.31h1.41v.78A.75.75,0,0,0,24.52,24.19Zm0-6.19,1.1,3.16H23.41Z' />
    </svg>
  );
}
