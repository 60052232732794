import { rem } from '@mantine/core';

interface IconProps extends React.ComponentPropsWithoutRef<'svg'> {
  size?: number | string;
}

export default function SportIconOutlined({
  size = 16,
  style,
  ...others
}: IconProps) {
  return (
    <svg
      viewBox='0 0 96 96'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      style={{ width: rem(size), height: rem(size), ...style }}
      {...others}
    >
      <path
        d='M28.1911 13.2482C24.4056 14.9797 20.9171 19.4528 19.2099 24.7917C16.5378 33.2328 18.5419 59.9993 22.7727 73.4907C25.3706 81.5712 29.9725 84.6735 38.3599 83.8799C44.1495 83.3027 47.8607 79.9839 61.8892 62.9573C77.2538 44.2713 78.0703 42.9726 77.9961 35.6858C77.9218 25.2245 72.355 19.5249 57.6584 14.6911C51.572 12.7431 49.3452 12.3824 40.9578 12.0938C32.4962 11.8774 30.9374 11.9495 28.1911 13.2482ZM29.8241 17.2884C27.2262 20.4629 25.2221 26.9561 24.5541 33.7379C23.5892 43.9106 27.6715 70.7492 31.1601 77.6031C32.4962 80.2725 32.6446 80.9219 31.6797 80.5611C27.82 79.2625 25.4448 74.3565 23.5892 63.318C20.1748 43.4777 19.8037 33.1607 22.4016 25.0081C23.9603 20.1021 28.0427 15.5569 31.5312 14.8354C31.6797 14.7633 30.8632 15.9176 29.8241 17.2884ZM50.7555 15.9176C61.9635 18.1542 69.8313 22.2665 73.0972 27.6775C75.5466 31.7899 75.9178 38.7881 73.7652 42.9726C72.4292 45.4978 51.4977 71.3985 47.0443 75.9437C44.0753 78.9739 39.4733 81.3547 37.6177 80.7776C36.8754 80.5611 35.5394 79.3346 34.7229 78.036C29.2303 69.3062 25.519 35.3972 29.0076 25.5853C32.6446 15.3404 37.395 13.176 50.7555 15.9176Z'
        fill='currentColor'
        stroke='currentColor'
      />
      <path
        d='M34.3519 28.6154C31.6056 32.8721 30.9376 44.2713 32.7932 54.5161C35.0199 66.9975 36.8756 69.0176 41.3291 64.1116C46.3021 58.5563 46.7475 55.2376 44.0012 42.6119C41.1064 29.5533 37.3951 24.0702 34.3519 28.6154ZM38.9539 35.1808C39.7703 37.2009 41.0322 42.3954 41.7744 46.6521C42.8136 52.9289 42.962 54.8047 42.2198 57.1134C41.1806 60.2878 38.7312 63.6787 37.9147 62.8851C36.8756 61.8751 34.723 48.9608 34.6488 43.4776C34.6488 37.4173 35.9106 30.4191 36.8756 30.9963C37.2467 31.2127 38.1374 33.0885 38.9539 35.1808Z'
        fill='currentColor'
        stroke='currentColor'
      />
      <path
        d='M56.3965 29.1926C51.8688 31.862 50.3843 37.5616 53.2049 41.9626C54.7636 44.5598 58.1037 46.5078 60.85 46.5078C63.819 46.5078 67.5303 43.5498 68.7921 40.1589C69.757 37.4895 69.757 36.768 68.7921 34.0986C67.5303 30.6355 63.819 27.7496 60.7016 27.7496C59.6624 27.7496 57.7326 28.399 56.3965 29.1926ZM64.6355 32.8721C65.7489 34.0986 66.5653 35.9744 66.5653 37.1287C66.5653 40.0146 63.2994 43.6219 60.7016 43.6219C55.1347 43.6219 52.7595 35.9744 57.0646 32.0785C59.4397 29.9141 62.3345 30.2026 64.6355 32.8721Z'
        fill='currentColor'
        stroke='currentColor'
      />
    </svg>
  );
}
