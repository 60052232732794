import { rem } from '@mantine/core';

interface IconProps extends React.ComponentPropsWithoutRef<'svg'> {
  size?: number | string;
}

export default function BoulderingIcon8a({
  size = 16,
  style,
  ...others
}: IconProps) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeWidth='1.5'
      viewBox='0 0 36 36'
      fill='currentColor'
      style={{ width: rem(size), height: rem(size), ...style }}
      {...others}
    >
      <path d='M22.66,3H13.34A3.52,3.52,0,0,0,9.82,6.52V23.19a3.52,3.52,0,0,0,3.52,3.52h.79l.23,3.61A2.64,2.64,0,0,0,16.87,33h2.18a2.64,2.64,0,0,0,2.51-2.68l.24-3.61h.86a3.52,3.52,0,0,0,3.52-3.52V6.52A3.52,3.52,0,0,0,22.66,3ZM15,20.6l0,.1a.81.81,0,0,1-.06.13l0,0-.11.36a1.06,1.06,0,0,1-.18.35l-.09.09a.78.78,0,0,1-.2.16l-.12.07-.24.07-.13,0a1,1,0,0,1-.38,0,1.08,1.08,0,0,1-.77-.91,1.26,1.26,0,0,1,0-.43l2-6.82A28.82,28.82,0,0,1,15,20.6ZM18,6.75a2.3,2.3,0,1,1-2.29,2.3A2.29,2.29,0,0,1,18,6.75Zm2.61,16.78-.21,3.18-.24,3.56a1.2,1.2,0,0,1-1.07,1.29H16.87a1.2,1.2,0,0,1-1.07-1.29l-.23-3.56-.31-4.59a1,1,0,0,0,.74-.36c.95-1.09.48-6,.07-9h3.79c-.4,3.06-.88,8,.08,9.05a1,1,0,0,0,.72.36Zm1.95-1.63a1,1,0,0,1-.38,0l-.13,0-.24-.07-.12-.07a.78.78,0,0,1-.2-.16l-.09-.09a1.06,1.06,0,0,1-.18-.35l-.32-1.07a39,39,0,0,1,.34-6.5v0l2,6.94a1.26,1.26,0,0,1,0,.43A1.08,1.08,0,0,1,22.52,21.9Z' />
    </svg>
  );
}
