import { rem } from '@mantine/core';

interface IconProps extends React.ComponentPropsWithoutRef<'svg'> {
  size?: number | string;
}

export default function CragIcon({ size, style, ...others }: IconProps) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      stroke='currentColor'
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeWidth='1.5'
      fill='currentColor'
      viewBox='0 0 36 36'
      style={{ width: rem(size), height: rem(size), ...style }}
      {...others}
    >
      <path d='M31.42,23.49a.49.49,0,0,0-.06-.17l-1.48-2.81a.74.74,0,0,0-1-.32l-.15.07.52-2.33L30.52,9s0-.09,0-.14a.4.4,0,0,0,0-.11h0a.61.61,0,0,0-.07-.17.42.42,0,0,0,0-.1v0L27.87,5.05a.72.72,0,0,0-.32-.25c-.13-.06-3.14-1.28-4.38-1.68a3.22,3.22,0,0,0-2.25.49A6.78,6.78,0,0,1,19.8,4L11.64,3a.69.69,0,0,0-.62.22c-.23.23-2.25,2.32-2.51,3.89-.12.72-1.51,5.19-2.41,8a.92.92,0,0,0,0,.29l.4,5,0,.07a.66.66,0,0,0,.06.2s0,0,0,.05l3.55,5.95a.75.75,0,0,0,1.29-.77L8.15,20.5l2.36-2.37a.69.69,0,0,0,.16-.22l1.47-3.33a.75.75,0,1,0-1.38-.6L9.35,17.17,7.83,18.7l-.26-3.25C8,14.27,9.82,8.37,10,7.37a7.88,7.88,0,0,1,1.84-2.83l7.89,1h.18A5.73,5.73,0,0,0,21.54,5a5,5,0,0,1,1.16-.43c1,.33,3.39,1.29,4.09,1.57l1.67,2.28L25.13,9a.75.75,0,0,0,.14,1.48h.14l3.47-.66-1.1,7.84L27.05,21v.07l-3.61,1.75a.75.75,0,0,0-.41.55l-1,5.83-2.63.51L17.94,29l.14-7.75,2-2.56a.77.77,0,0,0,.15-.49l-.29-9.63a.76.76,0,0,0-.6-.71l-4-.81a.74.74,0,0,0-.89.58.76.76,0,0,0,.59.89l3.4.69L18.73,18l-2,2.55a.72.72,0,0,0-.16.44l-.16,8.48a.43.43,0,0,0,0,.11.9.9,0,0,0,0,.16.54.54,0,0,0,.07.13.83.83,0,0,0,.08.12l.13.1a.71.71,0,0,0,.09.07l2.09,1a.76.76,0,0,0,.33.08h.15l2.32-.46-.08.44A.76.76,0,0,0,22,32l1.35.88a.73.73,0,0,0,.41.12h6.06a.74.74,0,0,0,.54-.23l2.44-2.51a.77.77,0,0,0,.19-.71Zm-1.93,8H24L23.22,31l1.22-7,4.45-2.17L30,23.94l1.43,5.57Z' />
      <path d='M6.29,22.09a.78.78,0,0,0-1.41,0L3,27.36a.76.76,0,0,0,.71,1H4.84v.55a.75.75,0,0,0,1.5,0v-.55H7.42a.76.76,0,0,0,.71-1ZM4.81,26.86l.78-2.24.78,2.24Z' />
      <path d='M14.12,24.29a.78.78,0,0,0-1.42,0l-2.16,6.19a.76.76,0,0,0,.71,1h1.41v.77a.75.75,0,0,0,1.5,0v-.77h1.41a.75.75,0,0,0,.7-1ZM12.31,30l1.1-3.17L14.51,30Z' />
    </svg>
  );
}
