import { rem } from '@mantine/core';

interface IconProps extends React.ComponentPropsWithoutRef<'svg'> {
  size?: number | string;
}

export default function ClimberIcon({ size, style, ...others }: IconProps) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      stroke='currentColor'
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeWidth='1.5'
      viewBox='0 0 36 36'
      fill='currentColor'
      fillRule='evenodd'
      clipRule='evenodd'
      style={{ width: rem(size), height: rem(size), ...style }}
      {...others}
    >
      <path d='M32.84,22.48a.74.74,0,0,0-.49-.29,2.78,2.78,0,0,0-2.86,1.52l-4.14-1.24a9.66,9.66,0,0,0-3.1-.45h-.4l.74-1.27a4.22,4.22,0,0,1,3.62-2.08h3.5a.74.74,0,0,0,.74-.65L31,13.84a.8.8,0,0,0-.15-.56.75.75,0,0,0-.5-.28,2.77,2.77,0,0,0-2.91,1.64l-3.59-.33a5.67,5.67,0,0,0-4.93,2.07l-1.5,1.84L17,16.64a12.89,12.89,0,0,0,3.65-4.11,13.26,13.26,0,0,0,1.17-6.38A1.69,1.69,0,0,0,21.34,5a1.51,1.51,0,0,0-1.11-.44,1.66,1.66,0,0,0-1.6,1.66c0,.18,0,4.41-3.52,5.56a7.4,7.4,0,0,1-1.59.33,3.79,3.79,0,1,0-4,1c-3.24,1.4-6.16,4.26-6.44,6.5-.34,2.78,1.4,5.1,4.79,6.38A1.73,1.73,0,0,0,10,25.38a1.47,1.47,0,0,0,.13-1.14,2,2,0,0,0-.94-1.13c-1.57-.88-2.47-1.84-2.42-2.26,0-.18.3-.47,1-.74L10.74,24l-.07.2a9.78,9.78,0,0,0-.47,3v2.2a2,2,0,0,0,2,2h1.51a2,2,0,0,0,2-2V27.48a9.55,9.55,0,0,0,4,.09l2.66-.5A9.34,9.34,0,0,1,25.28,27l6.32.86h.09a.76.76,0,0,0,.46-.16.74.74,0,0,0,.29-.5L33,23A.7.7,0,0,0,32.84,22.48ZM8.43,9.58a2.3,2.3,0,1,1,2.29,2.3A2.3,2.3,0,0,1,8.43,9.58ZM14.27,27v2.4a.53.53,0,0,1-.53.53H12.23a.53.53,0,0,1-.53-.53v-2.2a7.88,7.88,0,0,1,.21-1.82c.05.05.11.08.17.13a5,5,0,0,0,.5.41,8.54,8.54,0,0,0,.78.56c.12.08.22.18.35.25s.23.09.34.15S14.19,27,14.27,27Zm16.78-.72-5.57-.75a9.69,9.69,0,0,0-1.42-.1,10.72,10.72,0,0,0-1.95.18l-2.66.5a7.47,7.47,0,0,1-4.68-.55c-.16-.08-.32-.15-.47-.24a7.78,7.78,0,0,1-.93-.66l-.3-.27a8.1,8.1,0,0,1-.89-1h0L8.7,18.77a.77.77,0,0,0-.82-.27c-.59.18-2.41.72-2.57,2.19s1.93,3,3.2,3.73c.09,0,.21.18.2.25a.6.6,0,0,1-.35,0c-1.58-.6-4.18-2-3.83-4.79.22-1.74,3.21-4.68,6.61-5.71a9.85,9.85,0,0,1,2.3-.47,10.55,10.55,0,0,0,2.13-.43c4.56-1.5,4.56-6.78,4.55-6.92s.05-.2.17-.11a11.81,11.81,0,0,1-1,5.62,11.56,11.56,0,0,1-3.62,3.9.77.77,0,0,0-.29.83l1,3.46a.74.74,0,0,0,.57.52.73.73,0,0,0,.73-.26l2.41-2.95a4.19,4.19,0,0,1,3.62-1.52l4.19.39a.75.75,0,0,0,.81-.63,1.34,1.34,0,0,1,.67-.94l-.34,2.55H26.21A5.71,5.71,0,0,0,21.3,20l-1.4,2.4a.72.72,0,0,0,0,.75.75.75,0,0,0,.65.38h1.73a9.47,9.47,0,0,1,2.64.38l4.84,1.46a.76.76,0,0,0,.95-.6,1.32,1.32,0,0,1,.67-.94Z' />
    </svg>
  );
}
